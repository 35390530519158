import {
  IContactInfo,
  IConversationAuthClient,
  IConversationAuthCode,
} from "@auvious/integrations";
import {
  AuviousRtcService,
  CLAIM_APPLICATION_ID,
  CLAIM_CONFERENCE_ID,
  CLAIM_CUSTOMER_ID,
  CLAIM_ORGANIZATION_ID,
  GenericErrorHandler,
  UserService,
  debugError,
} from "../../../core-ui";
import { ApiResource, IJwtTokenResponse, Util } from "@auvious/common";
import { sessionStore } from "@auvious/utils";

const KEY_SESSION_ID = "auvious.session.id";
interface IDCAuthCode extends IConversationAuthCode {
  organizationId: string;
  conferenceId: string;
  customerId: string;
  touchpointId: string;
  idempotentId?: string;
  subject: string;
  contactEmail: string;
}

export class DigitalConnectAuthClient implements IConversationAuthClient {
  private _apiResource: ApiResource;
  private _token: IJwtTokenResponse;
  private _authCode: IDCAuthCode;
  private _sessionId: string;

  constructor(
    rtcService: AuviousRtcService,
    private userService: UserService
  ) {
    rtcService.common$.subscribe((common) => {
      this._apiResource = common.apiResourceFactory(
        "api/digital-connect/talkdesk/conversations"
      );
      this._token = common.auth.token.toJSON();
    });
  }

  async authenticate(code: IDCAuthCode): Promise<IJwtTokenResponse> {
    this._authCode = code;
    try {
      this._sessionId = sessionStore.getItem(KEY_SESSION_ID);
    } catch (ex) {
      debugError(ex);
    }
    if (!this._sessionId) {
      this._sessionId = Util.uuidgen();
      sessionStore.setItem(KEY_SESSION_ID, this._sessionId);
    }
    return this._token;
  }

  async logout() {
    this._authCode = undefined;
    this._sessionId = undefined;
    sessionStore.removeItem(KEY_SESSION_ID);
  }

  getAuthCode(contact: IContactInfo, touchpointId: string): IDCAuthCode {
    const user = this.userService.getActiveUser();
    return {
      applicationId: user.getClaim(CLAIM_APPLICATION_ID),
      touchpointId,
      subject: contact.subject,
      contactEmail: contact.email,
      customProperties: { ...contact },
      organizationId: user.getClaim(CLAIM_ORGANIZATION_ID),
      conferenceId: user.getClaim(CLAIM_CONFERENCE_ID),
      customerId: user.getClaim(CLAIM_CUSTOMER_ID),
    };
  }

  async register(): Promise<{ conversationId: string }> {
    const response: { conversationId: string } = await this._apiResource.create(
      this._authCode,
      {
        urlPostfix: "customer",
        headers: {
          "X-Idempotency-ID": this._sessionId,
        },
      }
    );

    await this._apiResource.create(
      {
        conversationId: response.conversationId,
      },
      {
        urlPostfix: "accept",
      }
    );

    return response;
  }
}
