import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { IConference } from "@auvious/rtc";
import { localStore } from "@auvious/utils";
import { init, captureException, setTags } from "@sentry/angular";
import {
  AppConfigService,
  AuviousRtcService,
  UserService,
} from "../../core-ui";

import { AUVIOUS_PURECLOUD_VERSION } from "../app.enums";
import { debugError } from "../app.utils";

const isSentryEnabled = () =>
  location.hostname !== "localhost" &&
  !localStore.getItem("auvious.sentry.disabled");

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    if (isSentryEnabled()) {
      const config = this.injector.get(AppConfigService);
      const user = this.injector.get(UserService);
      const rtc = this.injector.get(AuviousRtcService);

      init({
        // integrations: [
        //   browserTracingIntegration({
        //     enableLongAnimationFrame: false,
        //     enableLongTask: false,
        //     enableHTTPTimings: false,
        //   }),
        // ],
        // tracesSampleRate: 1.0,
        dsn: config.sentryDSN,
        environment: config.sentryEnvironment,
        release: window[AUVIOUS_PURECLOUD_VERSION] || "NO-VERSION",
        beforeBreadcrumb(event, hint) {
          try {
            const target = hint?.event?.target;
            if (target?.tagName) {
              event.message = `${event.message} (${
                target.hasAttribute("data-tid")
                  ? target.getAttribute("data-tid")
                  : target.parentElement.hasAttribute("data-tid")
                  ? target.parentElement.getAttribute("data-tid")
                  : target.innerText.trim().slice(0, 100)
              })`;
            }
          } catch {}

          return event;
        },
      });

      user.userChanged$.subscribe((event) => {
        if (event.user)
          setTags({
            userId: event.user.getId(),
            role: event.user.getRoles()[0],
          });
      });
    }
  }

  handleError(error) {
    captureException(error.originalError || error);
    console.error(error);
  }
}

@Injectable()
export class GenericErrorHandler implements ErrorHandler {
  constructor(private sentry: SentryErrorHandler) {}

  handleError(error) {
    if (isSentryEnabled()) {
      return this.sentry.handleError(error);
    } else {
      debugError(error);
    }
  }
}

class LocalErrorHandler implements ErrorHandler {
  handleError(error) {
    debugError(error);
  }
}

export function provideErrorHandler(injector: Injector): ErrorHandler {
  if (isSentryEnabled()) {
    return new SentryErrorHandler(injector);
  } else {
    return new LocalErrorHandler();
  }
}
